import React from 'react';
import FlexContainer from '../global/containers/FlexContainer';
import Text from '../global/components/Text';
import Title from '../global/components/Title';
import Button from '../global/components/Button';
import Spacer from '../global/components/Spacer';
import COLORS from '../constants/COLORS';
import BasicContainer from '../global/containers/BasicContainer';
import { useHistory } from 'react-router';
import useQueryParams from '../global/hooks/useQueryParams';
import plans from '../config';
import Card from '../global/components/Card';
import { Redirect } from 'react-router-dom';

const Confirm = () => {
    const history = useHistory();
    const query = useQueryParams();

    const plan = query.get('plan');
    const display = plans.find((p) => p.id == plan);

    if (!display) return <Redirect to="/" />;

    return (
        <BasicContainer width="100%">
            <Spacer height="50px" />
            <FlexContainer>
                <FlexContainer justifyContent="center" width="15%">
                    <Button icon={{ src: '/assets/Icon/left.png', width: '15px' }} onClick={() => history.goBack()}>
                        Return
                    </Button>
                </FlexContainer>
                <Spacer width="100px" />

                <BasicContainer padding="0px 20px 0px 20px" width="100%">
                    <BasicContainer width="70%">
                        <Text fontSize="24px" whiteSpace="nowrap">
                            THANK YOU FOR YOUR SUBSCRIPTION
                        </Text>
                        <Spacer height="50px" />
                        <Text lineHeight="1.5em">
                        We will contact you within 24 hours to talk about your needs and help you with technical
                        support.
                        Our artistic director will schedule a meeting with you to talk with you and about you, your desire
                        and start our relationship based on trust, understanding and connection.
                        We are looking forward to it.
                        Thank you.
                        </Text>
                    </BasicContainer>
                </BasicContainer>
                <FlexContainer flexWrap="wrap" justifyContent="space-between" width="70%" margin="auto">
                    <Card key={display.title} plan={display} />
                </FlexContainer>
                <FlexContainer width="15%"></FlexContainer>
            </FlexContainer>
            <Spacer height="50px" />
        </BasicContainer>
    );
};

export default Confirm;
