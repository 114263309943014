import React from 'react';
import FlexContainer from '../global/containers/FlexContainer';
import Button from '../global/components/Button';
import Spacer from '../global/components/Spacer';
import BasicContainer from '../global/containers/BasicContainer';
import { useHistory } from 'react-router';
import Card from '../global/components/Card';
import plans from '../config';

const Plans = () => {
    const history = useHistory();

    return (
        <BasicContainer width="100%">
            <Spacer height="50px" />
            <FlexContainer>
                <FlexContainer justifyContent="center" width="15%">
                    <Button icon={{ src: '/assets/Icon/left.png', width: '15px' }} onClick={() => history.goBack()}>
                        Return
                    </Button>
                </FlexContainer>
                <FlexContainer flexWrap="wrap" justifyContent="space-between" width="70%" margin="auto">
                    {plans.map((p) => (
                        <Card key={p.title} plan={p} />
                    ))}
                </FlexContainer>
                <FlexContainer width="15%"></FlexContainer>
            </FlexContainer>
            <Spacer height="50px" />
        </BasicContainer>
    );
};

export default Plans;
