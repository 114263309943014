const plans = [
    {
        id: 1,
        title: 'STANDARD',
        price: 100,
        items: [
            { text: 'UP TO 3 PLAYLISTS OR 30 HOURS OF MUSIC' },
            { text: 'NON EXCLUSIVE PLAYLISTS' },
            { text: '10% UPDATED EVERY 2 MONTHS' },
        ],
        plan_id: 'P-77K66377JF657152HMDU65VI',
        stripe_plan_id: 'price_1JEaJiLF3Qj9vVC1EZWs2si6',
    },
    {
        id: 2,
        title: 'DELUXE',
        price: 300,
        items: [
            { text: 'UP TO 4 PLAYLISTS OR 40 HOURS' },
            { text: 'EXCLUSIVE PLAYLISTS' },
            { text: '20% UPDATED EVERY 2 MONTHS' },
        ],
        plan_id: 'P-77K66377JF657152HMDU65VI',
        stripe_plan_id: 'price_1JEaJiLF3Qj9vVC1EZWs2si6',
    },
    {
        id: 3,
        title: 'PREMIUM',
        price: 500,
        items: [
            { text: 'UP TO 5 PLAYLISTS OR 50 HOURS' },
            { text: 'EXCLUSIVE PLAYLISTS' },
            { text: '20% UPDATED MONTHLY' },
            { text: 'ADJUSTMENT WITHIN A DAY'},
        ],
        plan_id: 'P-77K66377JF657152HMDU65VI',
        stripe_plan_id: 'price_1JEaJiLF3Qj9vVC1EZWs2si6',
    },
    {
        id: 4,
        title: 'PERSONALIZED',
        price: "CONTACT US",
        items: [
            { text: "We will be happy to tailor our services to your needs. Let's talk about" },
        ],
        plan_id: 'P-77K66377JF657152HMDU65VI',
        stripe_plan_id: 'price_1JEaJiLF3Qj9vVC1EZWs2si6',
    },

];

export default plans;
