import React, { Fragment } from 'react';
import { useHistory } from 'react-router';
import COLORS from '../../constants/COLORS';
import BasicContainer from '../containers/BasicContainer';
import FlexContainer from '../containers/FlexContainer';
import Button from './Button';
import Spacer from './Spacer';
import Text from './Text';
import Title from './Title';

const Card = ({ plan, withButton = true }) => {
    const history = useHistory();

    const handleRedirect = () => {
        if (plan.price != "CONTACT US")
            history.push({ pathname: `/plan/${plan.id}`, state: plan });
    };

    return (
        <FlexContainer
            margin="10px 10px 10px 10px"
            borderRadius="5px"
            padding="20px"
            direction="column"
            width="300px"
            backgroundColor={COLORS.WHITE}
        >
            <FlexContainer width="100%" justifyContent="space-between" alignItems="center">
                <Title marginBottom="0px" as="h2">
                    {plan.title}
                </Title>
                <Spacer width="10px" />
                {/* <Button whiteSpace="nowrap" fontSize="12px" primaryColor={COLORS.RED}>
                    One month free
                </Button> */}
            </FlexContainer>
            <Spacer height="10px" />
            <div style={{ height: 2, backgroundColor: COLORS.DARK_DIVIDER, width: '100%' }}></div>
            <Spacer height="15px" />
            <FlexContainer direction="column" width="100%" style={{ minHeight: 300 }}>
                {plan?.items.map((item, i) => (
                    <Fragment key={i}>
                        <FlexContainer alignItems="center">
                            <img width="15px" alt="check" src="/assets/Icon/check.png" />
                            <Spacer width="20px" />
                            <Text fontSize="14px" color={COLORS.BLACK}>
                                {item.text}
                            </Text>
                        </FlexContainer>
                        <Spacer height="10px" />
                    </Fragment>
                ))}
            </FlexContainer>
            <FlexContainer width="100%" justifyContent="center">
                <Text color={COLORS.BLACK} fontSize="20px">{`${plan.price != 'CONTACT US' ? plan.price + "$/month" : ""}`}</Text>
            </FlexContainer>
            <Spacer height="10px" />
            {withButton && (
                <FlexContainer width="100%" justifyContent="center">
                    <Button onClick={handleRedirect} inverted border="1px solid red" colorOnHover>
                        {plan.price != 'CONTACT US' ? "Subscribe" : 'Contact Us'}
                    </Button>
                </FlexContainer>
            )}
        </FlexContainer>
    );
};

export default Card;
