import React, { Fragment, useState } from 'react';
import FlexContainer from '../global/containers/FlexContainer';
import Text from '../global/components/Text';
import Title from '../global/components/Title';
import Button from '../global/components/Button';
import Spacer from '../global/components/Spacer';
import COLORS from '../constants/COLORS';
import BasicContainer from '../global/containers/BasicContainer';
import { useHistory, useLocation } from 'react-router';
import Card from '../global/components/Card';
import { PayPalButton } from 'react-paypal-button-v2';
import axios from 'axios';

const PlanDetails = () => {
    const history = useHistory();
    const { state } = useLocation();

    const [paymentMode, setPaymentMode] = useState('stripe');

    if (!state) {
        history.push('/plans');
        return null;
    }

    const handleStripeRedirect = async () => {
        var data = {
            priceId: state.stripe_plan_id,
        };
        var config = {
            method: 'post',
            url: 'http://localhost:3030/stripe-checkout',

            data: data,
        };
        const res = await axios(config);
        window.open(res.data.url, '_blank');
    };

    return (
        <BasicContainer width="100%">
            <Spacer height="50px" />
            <FlexContainer>
                <FlexContainer justifyContent="center" width="15%">
                    <Button icon={{ src: '/assets/Icon/left.png', width: '15px' }} onClick={() => history.goBack()}>
                        Return
                    </Button>
                </FlexContainer>
                <FlexContainer flexWrap="wrap" justifyContent="space-between" width="40%">
                    <Card plan={state} withButton={false} />
                </FlexContainer>
                <FlexContainer direction="column" style={{ borderTop: '1px white solid' }}>
                    <Spacer height="20px" />
                    <Text fontSize="24px">PAYMENTS INFORMATIONS</Text>
                    <Spacer height="40px" />

                    <FlexContainer width="100%">
                        <FlexContainer
                            onClick={() => setPaymentMode('stripe')}
                            className="pointer"
                            style={{ borderBottom: `1px ${paymentMode === 'stripe' ? COLORS.RED : 'white'} solid` }}
                            justifyContent="center"
                            width="50%"
                        >
                            <Text color={paymentMode === 'stripe' ? COLORS.RED : 'white'} fontSize="18px">
                                Credit Card
                            </Text>
                        </FlexContainer>
                        <FlexContainer
                            onClick={() => setPaymentMode('paypal')}
                            className="pointer"
                            style={{ borderBottom: `1px ${paymentMode === 'paypal' ? COLORS.RED : 'white'} solid` }}
                            justifyContent="center"
                            width="50%"
                        >
                            <Text color={paymentMode === 'paypal' ? COLORS.RED : 'white'} fontSize="18px">
                                Paypal
                            </Text>
                        </FlexContainer>
                    </FlexContainer>
                    <Spacer height="20px" />

                    {paymentMode === 'paypal' && (
                        <PayPalButton
                            options={{ vault: true }}
                            createSubscription={(data, actions) => {
                                return actions.subscription.create({
                                    plan_id: state.plan_id,
                                });
                            }}
                            onApprove={(data, actions) => {
                                // Capture the funds from the transaction
                                return actions.subscription.get().then(function (details) {
                                    // Show a success message to your buyer
                                    alert('Subscription completed');

                                    // OPTIONAL: Call your server to save the subscription
                                    return fetch('/paypal-subscription-complete', {
                                        method: 'post',
                                        body: JSON.stringify({
                                            orderID: data.orderID,
                                            subscriptionID: data.subscriptionID,
                                        }),
                                    });
                                });
                            }}
                        />
                    )}
                    {paymentMode === 'stripe' && (
                        <Button onClick={handleStripeRedirect} primaryColor={COLORS.RED}>
                            Subscribe
                        </Button>
                    )}
                </FlexContainer>
            </FlexContainer>

            <Spacer height="50px" />
        </BasicContainer>
    );
};

export default PlanDetails;
