import React from 'react';
import styled, { useTheme } from 'styled-components';

const StyledText = styled.span`
    font-size: ${(props) => (props.fontSize ? props.fontSize : null)};
    color: ${(props) => props.color};
    font-weight: ${(props) => (props.bold ? 'bold' : props.fontWeight)};
    text-transform: ${(props) => (props.uppercase ? 'uppercase' : null)};
    font-family: 'CynthoNext-Light';
    text-align: ${(props) => props.textAlign};
    z-index: ${(props) => props.zIndex};
    white-space: ${(props) => props.whiteSpace};
    margin: ${(props) => props.margin};
    line-height: ${(props) => props.lineHeight};
`;

const Text = ({
    fontSize,
    color,
    bold,
    children,
    as = 'span',
    text,
    uppercase,
    className,
    onClick,
    fontWeight = 'normal',
    textAlign = 'left',
    whiteSpace = 'normal',
    zIndex,
    margin,
    lineHeight,
}) => {
    const theme = useTheme();
    const usedColor = color ?? theme.colors.contrast;

    return (
        <StyledText
            onClick={onClick}
            className={className}
            as={as}
            bold={bold}
            fontSize={fontSize}
            uppercase={uppercase}
            color={usedColor}
            fontWeight={fontWeight}
            textAlign={textAlign}
            zIndex={zIndex}
            whiteSpace={whiteSpace}
            margin={margin}
            lineHeight={lineHeight}
        >
            {children || text}
        </StyledText>
    );
};

export default Text;
