import React from 'react';
import FlexContainer from '../global/containers/FlexContainer';
import Text from '../global/components/Text';
import Title from '../global/components/Title';
import Button from '../global/components/Button';
import Spacer from '../global/components/Spacer';
import COLORS from '../constants/COLORS';
import BasicContainer from '../global/containers/BasicContainer';
import { useHistory } from 'react-router';

const Landing = () => {
    const history = useHistory();

    const handleRedirect = () => {
        history.push('/plans');
    };

    return (
        <FlexContainer width="75%" margin="auto">
            <FlexContainer width="70%" direction="column">
                <Spacer height="40px" />
                <Title color="white">MAISON LABTONIC PLAY</Title>
                <BasicContainer width="60%">
                    <Text lineHeight="1.5em">
                    We do not believe in Algorithm when it comes to understand your brand and translate your
                    identity into music experience.
                    All our music selections are hand picked, creating unique moods at any time of the day.
                    Our curated playlists are available on our player to broadcast your sound signature in all your
                    locations.
                    Maison Labtonic Player offers different subscription options.
                    </Text>
                </BasicContainer>
                <Spacer height="120px" />
                <Text fontSize="28px">From 120$/month</Text>
                <Spacer height="15px" />
                <Button primaryColor={COLORS.RED} onClick={handleRedirect}>
                    Subscribe
                </Button>
            </FlexContainer>
            <FlexContainer width="30%" direction="column">
                <img width="80%" src="/assets/Images/Mockup.png" alt="preview" />
            </FlexContainer>
        </FlexContainer>
    );
};

export default Landing;
