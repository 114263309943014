import React from 'react';
import styled, { useTheme } from 'styled-components';
import COLORS from '../../constants/COLORS';

const getFilter = ({ inverted, invertFilter, disabled }) => {
    if (disabled) return 'none';
    if (invertFilter) return inverted ? 'brightness(95%)' : 'brightness(110%)';
    return inverted ? 'brightness(110%)' : 'brightness(95%)';
};

const customHover = () => {
    return `color: white; background-color: red;`;
};

const StyledButton = styled.button`
    width: ${(props) => props.width};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${(props) => (props.rounded ? '12px 45px 12px 45px' : '8px 15px 8px 15px')};
    background-color: ${(props) => (props.inverted ? props.secondaryColor : props.primaryColor)};
    color: ${(props) => (props.inverted ? props.primaryColor : props.secondaryColor)};
    border: ${(props) => props.border};
    font-size: ${(props) => props.fontSize};
    max-width: ${(props) => props.maxWidth};
    white-space: ${(props) => props.whiteSpace};
    border-radius: ${(props) => (props.rounded ? '35px' : '5px')};

    :hover {
        ${(props) => (props.colorOnHover ? customHover() : `filter: ${(props) => getFilter(props)};`)}

    ${(props) =>
        props.fromModal && !props.disabled
            ? ':hover { background-color: white; color: black; } :active { background-color: transparent; color: white;}'
            : ''}

    ${(props) => (props.disabled ? `color: ${COLORS.GREY};` : '')}

    :focus {
        outline: none;
    }

    :active {
        filter: brightness(100%);
    }
`;

const Button = ({
    onClick,
    text,
    children,
    primaryColor,
    secondaryColor,
    icon,
    rounded,
    inverted,
    disabled,
    fullWidth,
    squared,
    type = 'button',
    whiteSpace,
    fontSize = '24px',
    invertFilter,
    border = 'none',
    fromModal,
    width = 'fit-content',
    colorOnHover,
}) => {
    const theme = useTheme();
    let tmpPrimary = primaryColor ?? theme.colors.background;
    let tmpSecondary = secondaryColor ?? theme.colors.buttonContrast;

    return (
        <StyledButton
            onClick={onClick}
            inverted={inverted}
            primaryColor={tmpPrimary}
            secondaryColor={tmpSecondary}
            className={disabled ? null : 'pointer'}
            disabled={disabled}
            fullWidth={fullWidth}
            squared={squared}
            rounded={rounded}
            type={type}
            whiteSpace={whiteSpace}
            fontSize={fontSize}
            invertFilter={invertFilter}
            border={border}
            fromModal={fromModal}
            width={width}
            colorOnHover={colorOnHover}
        >
            {icon && <img alt={`button-${icon.src}`} src={icon.src} width={icon.width} height={icon.height} style={{ marginRight: 10 }} />}
            {text || children}
        </StyledButton>
    );
};

export default Button;
