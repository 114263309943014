import React from 'react';
import styled from 'styled-components';

const StyledSpacer = styled.div`
    height: ${(props) => props.height};
    width: ${(props) => props.width};
`;

const Spacer = ({ width = '40px', height = '40px' }) => {
    return <StyledSpacer width={width} height={height} />;
};

export default Spacer;
