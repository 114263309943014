import React, { Fragment } from 'react';
import styled from 'styled-components';
import BasicContainer from '../containers/BasicContainer';
import FlexContainer from '../containers/FlexContainer';
import Text from '../components/Text';
import Spacer from '../components/Spacer';
import { useHistory } from 'react-router-dom';

const StyledDivider = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.divider};
    margin-bottom: 30px;
`;

const Header = () => {
    const history = useHistory();

    return (
        <BasicContainer width="75%" margin="auto">
            <img onClick={() => history.push('/')} className="pointer" width="20%" src="/assets/Logo/logo-full.jpg" alt="logo-mlp" />
        </BasicContainer>
    );
};

const Footer = () => {
    const medias = [
        { name: 'instagram', src: '/assets/Icon/instagram.png' },
        { name: 'vimeo', src: '/assets/Icon/vimeo.png' },
        { name: 'soundcloud', src: '/assets/Icon/soundcloud.png' },
    ];

    return (
        <FlexContainer direction="column" width="75%" margin="auto">
            <StyledDivider />
            <FlexContainer width="100%" justifyContent="space-between">
                <FlexContainer>
                    {medias.map((media) => (
                        <Fragment key={media.name}>
                            <img className="pointer" width="15px" src={media.src} alt={media.name} />
                            <Spacer width="10px" />
                        </Fragment>
                    ))}
                </FlexContainer>
                <FlexContainer>
                    <Text>maison@labtonic.com</Text>
                    <Spacer width="10px" />
                    <Text>+1 (917) 539-9512</Text>
                </FlexContainer>
            </FlexContainer>
        </FlexContainer>
    );
};

const StyledPage = styled.div`
    background-color: ${(props) => props.theme.colors.background};
`;

const Page = ({ children }) => {
    return (
        <StyledPage>
            <Header />
            {children}
            <Spacer height="20px" />
            <Footer />
        </StyledPage>
    );
};

export default Page;
