import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Page from '../global/containers/Page';
import Confirm from '../pages/Confirm';
import Landing from '../pages/Landing';
import PlanDetails from '../pages/PlanDetails';
import Plans from '../pages/Plans';

const Routes = () => {
    //const { isAuthenticated } = useAuth();

    const routes = [
        { auth: false, path: '/', component: <Landing /> },
        { auth: false, path: '/plans', component: <Plans /> },
        { auth: false, path: '/plan/:id?', component: <PlanDetails /> },
        { auth: false, path: '/confirm', component: <Confirm /> },
    ];

    return (
        <Switch>
            {routes.map((r) => (
                <Route key={r.path} exact path={r.path}>
                    <Page>{r.component}</Page>
                </Route>
            ))}
            <Route path="*">
                <Redirect to="/" />
            </Route>
        </Switch>
    );
};

export default Routes;
