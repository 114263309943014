import './App.css';
import { ThemeProvider } from 'styled-components';
import { darkTheme } from './theme/themes';
import Routes from './router/Routes';

const App = () => {
    return (
        <ThemeProvider theme={darkTheme}>
            <Routes />
        </ThemeProvider>
    );
};

export default App;
